import { useState, useEffect } from "react";
import { useSampleEdit } from "../../../api/posts";

export function Reachback({ sample }) {
  //state variables
  const [isReachback, setIsReachback] = useState(sample.reachback);
  const [isReachbackEmailSent, setIsReachbackEmailSent] = useState(
    sample.isReachbackEmailSent
  );

  const {
    mutateAsync,
    isSuccess: editSuccess,
    isError: editError,
  } = useSampleEdit(sample._id);

  //when the page first loads, check if reachback is true and email has not been sent
  useEffect(() => {
    if (isReachback && !isReachbackEmailSent) {
      handleReachback();
    }
  }, []);

  //function for sending reachback email and updating isReachbackEmailSent value in database
  const sendReachbackEmail = async (emailData) => {
    // sendReachbackEmail function
    try {
      console.log("Sending email...");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: "reachback@spectraplasmonics.com",
            from: "Reachback Service Requested",
            subject: `Reachback Sample: ${emailData.sampleId}, Customer ID: ${emailData.customerId} `,
            html: `
                    <h1>Sample information:</h1>
                    <p>Customer ID: ${emailData.customerId}</p>
                    <p>Processed Date: ${emailData.processedDate}</p>
                    <p>Sample ID: ${emailData.sampleId}</p>
                    <p>Was there an error with the trace scan? ${emailData.isErrorT}</p>
                    <p>Was there an error with the bulk scan? ${emailData.isErrorB}</p>
                    <p>Notes: ${emailData.notes}</p>
                `,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log(data.messageId);
        console.log("Email sent successfully!");
        setIsReachbackEmailSent(true);
        await mutateAsync({
          id: sample._id,
          data: { isReachbackEmailSent: true },
        });
      } else {
        console.error("Error sending email:", data.error);
        console.error("Email sending failed!");
        setIsReachbackEmailSent(false);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      console.error("Email sending failed!");
      setIsReachbackEmailSent(false);
    }
  };

  const handleReachback = async () => {
    const emailData = {
      customerId: sample.customerId,
      processedDate: sample.processedDate,
      sampleId: sample.sampleId,
      isErrorT: sample.isErrorT,
      isErrorB: sample.isErrorB,
      notes: sample.description,
    };

    await sendReachbackEmail(emailData);

    const editData = {
      id: sample._id,
      data: { reachback: true, reachbackComplete: "inProgress" },
    };
    await mutateAsync(editData);
  };

  const handleClick = () => {
    setIsReachback(true);
    handleReachback();
  };

  return (
    <div className='d-flex flex-row justify-content-center align-items-center flex-wrap pt-2'>
      {/* Reachback complete message */}
      {sample.reachbackComplete === "Complete" && (
        <div className='d-flex align-items-center justify-content-center mt-2 w-100 order-2'>
          <i
            className='bi bi-check-circle-fill'
            style={{
              fontSize: "large",
              color: "green",
              marginRight: "10px",
              marginBottom: "2px",
            }}
          ></i>
          <span
            style={{
              color: "black",
            }}
          >
            Reachback completed
          </span>
        </div>
      )}

      {/* Submit for Reachback button */}
      {sample.reachback === false && (
        <div className='d-flex flex-row justify-content-center align-items-center flex-wrap pt-2'>
          <h6 className=' mb-0 text-center me-2 text-wrap'>
            Are you concerned about the results? Send for Reachback Service.
          </h6>
          <div className='d-flex flex-column width-auto py-1'>
            <button
              type='submit'
              className={`btn btn-primary align-self-center  `}
              style={{ minWidth: "150px" }}
              onClick={handleClick}
              disabled={isReachback}
            >
              {isReachback ? "Already Submitted" : "Submit for Reachback"}
            </button>
            {isReachback ? (
              <p
                className='align-self-center text-center'
                style={{
                  color: "black",
                  fontStyle: "italic",
                }}
              >
                Reachback Submitted - We'll be in touch shortly!
              </p>
            ) : null}
          </div>
        </div>
      )}

      {/* Reachback in progress message */}
      {(sample.reachback === true ||
        sample.reachbackComplete === "inProgress") && (
        <div className='d-flex flex-column align-items-center justify-content-center mt-2 w-100'>
          <div className='d-flex align-items-center'>
            <i
              className='bi bi-exclamation-triangle-fill'
              style={{
                fontSize: "small",
                color: "#AE0000",
                marginRight: "10px",
              }}
            ></i>
            <span
              style={{
                color: "#AE0000",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              <small>
                {`${
                  sample.isErrorB && sample.isErrorT
                    ? "Bulk scan and trace scan"
                    : sample.isErrorB
                    ? "Bulk scan"
                    : sample.isErrorT
                    ? "Trace scan"
                    : "Displayed"
                } results are currently undergoing expert review and
                should be interpreted cautiously.`}
              </small>
            </span>
          </div>
          <div className='d-flex align-items-center'>
            <i
              className='bi bi-clock-fill'
              style={{
                fontSize: "small",
                color: "#F08021",
                marginRight: "10px",
              }}
            ></i>
            <span
              style={{
                color: "#F08021",
                fontWeight: "bold",
              }}
            >
              <small>Reachback Submitted - We'll be in touch shortly!</small>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";

// global context for state management
import { useSampleContext } from "../../../contexts";
import { useReachbackContext } from "../../../contexts/ReachbackContext";

// components
import { CardA } from "../../ui/Cards";
import { SequenceE } from "../../ui/Sequence";
import CustomModalB from "../../ui/ModalB";

// queries
import { useGetMeasurementBulk, useValidateSample } from "../../../api";
import { useEffect } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// lodash to help with object comparisons
const some = require("lodash.some");

const WizardBulk = ({ step, steps, changeStep, pageID }) => {
  const [scanResults, setScanResults] = useState([]);
  const [selected, setSelected] = useState([]);
  const [counter, setCounter] = useState(1);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = React.useState("Could not retrieve bulk scan.");
  const [loading, setLoading] = useState(false);
  const [unverifiedScan, setUnverifiedScan] = useState();
  const [showModal, setShowModal] = useState(false);

  const { initWasatchBulk, device } = useSampleContext();
  const { submittedErrorsB, updateSubmittedErrorsB } = useReachbackContext();
  const {
    data: bulkScan,
    status: bulkScanStatus,
    isFetching: isFetchingbulkScan,
    refetch: refetchbulkScan,
  } = useGetMeasurementBulk(
    device?.deviceId,
    "bulk-burst",
    connectorAppErrorLogger
  );

  const {
    data: validateResponse,
    status: validateStatus,
    isFetching: isFetchingValidate,
    refetch: refetchValidate,
  } = useValidateSample(unverifiedScan, sampleCheckErrorLogger);

  // const addT = (sequence) => {
  //   setSelected([...selected, sequence]);
  // };

  // Helper function to get the two newest scans
  const getTwoNewestScans = (results) => {
    return results.sort((a, b) => b.scanId - a.scanId).slice(0, 2);
  };

  // Initialize the scan results state with the two newest scans
  const [twoNewestScans, setTwoNewestScans] = useState(() =>
    getTwoNewestScans(scanResults)
  );

  // Update the two newest scans whenever scanResults change
  useEffect(() => {
    setTwoNewestScans(getTwoNewestScans(scanResults));
  }, [scanResults]);

  // Helper function to maintain the most recent two scans
  const updateRecentScans = (sequence) => {
    setSelected((prevSelected) => {
      // If there are already two scans, remove the second one before adding the new one
      if (prevSelected.length === 2) {
        return [prevSelected[1], sequence];
      } else {
        return [...prevSelected, sequence];
      }
    });
  };

  const removeT = (sequence) => {
    setSelected(selected.filter((s) => s.scanId !== sequence.scanId));
  };

  function connectorAppErrorLogger(err) {
    console.log("bulk scan error logged.");
    setLoading(false);
    setError(true);
    if (err.response?.data?.detail) {
      setErrMsg(
        `Connector App error [${err.response.status}]: ` +
          err.response?.data?.detail
      );
    } else {
      setErrMsg(
        `Connector App error [${err.response.status}]: Could not retrieve bulk scan. Check the Connector App logs.`
      );
    }
    console.error(err);
  }

  function sampleCheckErrorLogger(err) {
    console.log("Error logged.");
    setLoading(false);
    setError(true);
    setErrMsg(
      `Model Function error [${err.response.status}]. Please contact a Spectra Plasmonics administrator.`
    );
    console.error(err);
  }

  const handleScan = () => {
    console.log("Performing bulk scan");
    setLoading(true);
    setError(false);
    refetchbulkScan();
    setShowModal(false);
  };

  const handleModal = () => {
    // After completing the scan, show the modal
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // Once the bulk scan is retrieved, prepare it for validation
  useEffect(() => {
    if (!isFetchingbulkScan && step === pageID) {
      if (bulkScanStatus === "success") {
        setUnverifiedScan({ ...bulkScan, scanId: counter });
        setCounter(counter + 1);
      }
    }
  }, [bulkScan, isFetchingbulkScan]);

  // Only attempt to validate scan once react has set the 'unverifiedScan' variable
  useEffect(() => {
    if (unverifiedScan) {
      refetchValidate();
    }
  }, [unverifiedScan]);

  // Check the scan validate query is done
  useEffect(() => {
    if (!isFetchingValidate && step === pageID) {
      if (validateStatus === "success") {
        let verifiedScan = { ...unverifiedScan };

        verifiedScan.verified =
          validateResponse?.data?.errorCheck?.errorFlag === 0;

        // Always add errorCheck properties
        verifiedScan.errorCheck = {
          // errorFlag: subScan?.errorCheck?.errorFlag,
          // errorMessage: subScan?.errorCheck?.errorMessage,
          // errorType: subScan?.errorCheck?.errorType,
          errorFlag: validateResponse?.data?.errorCheck?.errorFlag,
          modelPrediction: validateResponse?.data?.errorCheck?.modelPrediction,
          reconstructionError:
            validateResponse?.data?.errorCheck?.reconstructionError_intensity,
          signalQuality: validateResponse?.data?.errorCheck?.signalQuality,
        };
        console.log(verifiedScan);

        setScanResults([...scanResults, verifiedScan]);
        setLoading(false);
        setError(false);
      } else if (validateStatus === "error") {
        console.error("Could not validate scan.");
      }
    }
  }, [validateResponse, isFetchingValidate]);

  if (step !== pageID) {
    return null;
  }

  // // handler function for the "Continue" button click event
  // function handleContinue() {
  //   // update the step to move forward
  //   changeStep("forward");
  // }

  const handleSelectSequence = (sequence) => {
    setSelected([sequence]);
  };

  return (
    <div className='shadow card d-flex flex-column flex-grow-1 '>
      <div className='card-body full-height justify-content-between'>
        <div
          className={
            twoNewestScans.length !== 0 && !error
              ? "d-flex justify-content-between flex-wrap"
              : "d-flex flex-column justify-content-between flex-grow-1 "
          }
        >
          <div
            className={
              twoNewestScans.length !== 0 && !error ? "col-12 col-md-6" : "row "
            }
          >
            <h5 className='card-title w-100'>
              <span>Amplifi ID Bulk Scan</span>
              <button
                className='btn ms-1 p-1'
                aria-label='Info'
                onClick={handleModal}
              >
                <i
                  className='bi bi-info-circle-fill'
                  style={{ color: "#1ca3f0" }}
                ></i>
              </button>
            </h5>

            <h6 className='card-subtitle mb-2 mb-md-4 text-muted w-100'>
              If Bulk Scan is not applicable, press "Skip Bulk Scan".
            </h6>
          </div>

          <div
            className={
              twoNewestScans.length !== 0 && !error
                ? "col-12 col-md-6 d-flex justify-content-start justify-content-md-end align-items-center"
                : "row pt-4 d-flex flex-column flex-grow-1 justify-content-center align-items-center"
            }
          >
            {!loading && (
              <button
                className='btn btn-link float-end order-1'
                type='button'
                onClick={() => {
                  initWasatchBulk(null);
                  changeStep("forward");
                  updateSubmittedErrorsB(false);
                }}
              >
                Skip Bulk Scan
              </button>
            )}
            <button
              className='btn btn-success float-end w-50 '
              type='button'
              variant='outline-success'
              onClick={() => handleScan()}
              disabled={loading}
            >
              {loading ? (
                <span>
                  <span
                    className='spinner-border spinner-border-sm'
                    aria-hidden='true'
                  ></span>
                  <span> Scanning...</span>
                </span>
              ) : (
                <span>Conduct Bulk Scan</span>
              )}
            </button>
            <CustomModalB
              showModal={showModal}
              handleClose={() => setShowModal(false)}
            />
          </div>
        </div>

        {/* Plots */}
        {twoNewestScans.length !== 0 && !error && (
          <form
            className={
              "row justify-content-evenly d-flex align-items-stretch flex-wrap flex-grow-1"
            }
          >
            {bulkScanStatus === "success" &&
              twoNewestScans.map((seq) => {
                return (
                  <div
                    className='col-12 col-xl-6 mt-2 spectrum-card-container '
                    key={seq.scanId}
                  >
                    <SequenceE
                      key={seq.scanId}
                      sequence={seq}
                      isSelected={selected.some((s) => s.scanId === seq.scanId)}
                      add={updateRecentScans}
                      remove={removeT}
                      valid={seq.verified}
                      // validateResponse={validateResponse}
                      onSelect={handleSelectSequence}
                    />
                  </div>
                );
              })}
          </form>
        )}

        {/* Back and Continue buttons */}

        {/* <div className="row mt-4">{error && <h6>{errMsg}</h6>}</div> */}
        <div className='row mt-4 align-content-center'>
          <span className='col-6 d-flex justify-content-center'>
            <button
              type='button'
              className='btn btn-secondary mx-auto w-75'
              onClick={() => changeStep("back")}
              disabled={loading}
            >
              Back
            </button>
          </span>
          <OverlayTrigger
            overlay={
              selected.length === 0 ? (
                <Tooltip id='tooltip-disabled'>
                  Please conduct and select a scan to continue. Otherwise, press
                  "Skip Bulk Scan".
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            <span className='col-6 d-flex justify-content-center'>
              <button
                type='button'
                className='btn btn-primary mx-auto w-75'
                onClick={() => {
                  // if (!submittedErrorsB) {
                  const hasError = selected.some(
                    (s) => s.errorCheck?.modelPrediction !== "Analyte"
                  );
                  console.log("hasError? : ", hasError);

                  if (hasError) {
                    updateSubmittedErrorsB(true); // Set submittedErrorsB to true
                  } else {
                    updateSubmittedErrorsB(false); // Set submittedErrorsB to false
                  }
                  // }
                  initWasatchBulk(selected);
                  changeStep("forward");
                }}
                disabled={selected.length === 0 || loading}
              >
                {selected.some(
                  (s) => s.errorCheck?.modelPrediction === "Analyte"
                ) || selected.length === 0
                  ? "Continue"
                  : "Continue and Submit for Reachback Service"}
              </button>
            </span>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

export default WizardBulk;

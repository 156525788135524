import React, { createContext, useContext } from "react";

import SampleCons from "../constants/sample";

import { find } from "lodash";

const ParamsContext = createContext();

export function ParamsProvider(props) {
  const coloursList = SampleCons.COLOURS;
  const texturesList = SampleCons.TEXTURES;
  const adverseList = SampleCons.AR_INTERVENTION;

  function getColour(id) {
    let colour;
    coloursList.map((item) => {
      item.colours.map((c) => {
        if (c.id === id) {
          colour = c;
          if (!colour.label) {
            colour["label"] = item.label;
          }
        }
      });
    });
    return colour;
  }

  function getTexture(id) {
    let texture;
    texturesList.map((item) => {
      if (item.id === id) {
        texture = item;
      }
    });
    return texture;
  }

  const getAdverse = (categories) => {
    const reactions = [];
    if (categories.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        reactions.push(
          find(adverseList, ["id", categories[i].categoryId]).label
        );
      }
      return reactions;
    }
    return reactions;
  };

  return (
    <ParamsContext.Provider value={{ getColour, getTexture, getAdverse }}>
      {props.children}
    </ParamsContext.Provider>
  );
}

export const useParamsContext = () => {
  const { getColour, getTexture, getAdverse } = useContext(ParamsContext);
  return { getColour, getTexture, getAdverse };
};

import axios from "axios";
import { se } from "date-fns/locale";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

export async function edit({ id, data }) {
  try {
    const res = await axios.post("/companion/view-edit-sample/edit-sample", {
      id: id,
      data: data,
    });
    return res.data;
  } catch (error) {
    console.error("Error: ", error);
  }
}

export async function commonDrugs(ldate, drug, selectedLocation) {
  const { data } = await axios.post("/companion/body-stats/commonDrugs", {
    ldate,
    drug,
    selectedLocation,
  });
  return data;
}

export async function adverseReactions(ldate, substance) {
  const { data } = await axios.post("/companion/body-stats/adverseReactions", {
    ldate,
    substance,
  });
  return data;
}

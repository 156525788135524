import { useEffect } from "react";

// react router
import { useParams } from "react-router-dom";

// api calls
import { useSample } from "./../api";
import { useCustomerType } from "../api/user";

// components
import SampleInformation from "../components/ViewSample/SampleInformation";
import SampleResults from "../components/ViewSample/SampleResults";
import OtherResults from "../components/ViewSample/OtherResults";
import PostConsumption from "../components/ViewSample/PostConsumption";
import Notes from "../components/ViewSample/Notes";

import Footer from "./../components/Footer";
import Navbar from "../components/SpectraNavbar";
import { CardA } from "./../components/ui/Cards";

export default function ViewSample() {
  const { id } = useParams();

  // api
  const { data: sample, isError, isSuccess, isLoading } = useSample(id);
  const {
    data: customerType,
    isLoading: isCustomerTypeLoading,
    isError: isCustomerTypeError,
  } = useCustomerType();

  useEffect(() => {
    console.log("sample:", sample);
  }, [sample]);

  if (isError) {
    return (
      <div className='flex-container'>
        <Navbar signedIn={false} />
        <div
          className='px-sm-3 px-lg-5 py-3 flex-body'
          style={{ marginTop: "79px" }}
        >
          <CardA title='Sample not found' />
        </div>
        <Footer />
      </div>
    );
  }

  if (isLoading || isCustomerTypeLoading) {
    // if (isLoading || (isRefSuccess === false && isRefError === false)) {
    return (
      <div className='flex-container'>
        <Navbar signedIn={false} />
        <div
          className='px-sm-3 px-lg-5 py-3 flex-body'
          style={{ marginTop: "79px" }}
        >
          <div
            className='alert mx-3'
            style={{
              backgroundColor: "#7ECBF7",
              fontWeight: "bold",
            }}
            role='alert'
          >
            Fetching sample ...
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className='flex-container'>
      <Navbar signedIn={false} />
      <div
        className='px-sm-3 px-lg-5 py-3 flex-body'
        style={{ marginTop: "79px" }}
      >
        <CardA title='Results Summary'>
          <div className='row full-height'>
            <SampleInformation sample={sample} />
          </div>
          <div className='row full-height'>
            <SampleResults sample={sample} />
          </div>
          {customerType === "harm-reduction" && (
            <div className='row full-height'>
              <PostConsumption
                id={sample._id}
                postConsumption={sample.postConsumption}
                categories={sample.categories}
              />
            </div>
          )}
          <div className='row full-height'>
            <Notes id={sample._id} sampleNotes={sample.description} />
          </div>
          <div className='row full-height'>
            <OtherResults id={sample._id} otherResults={sample.otherResults} />
          </div>
        </CardA>
      </div>
      <Footer />
    </div>
  );
}

// Barchart component

import BarChartAdverse from "./charts/BarChartAdverse";

const AdverseReactionPlot = ({ period, data, customerType }) => {
  const datasets = {
    7: {
      unit: "day",
    },
    14: {
      unit: "day",
    },
    30: {
      unit: "day",
    },
    60: {
      unit: "week",
    },
    90: {
      unit: "week",
    },
    180: {
      unit: "week",
    },
  };

  const getUnit = () => {
    return datasets[period].unit;
  };

  // const getData = () => {
  //   let data = {
  //     datasets: [
  //       {
  //         label: "Adverse Reaction",
  //         data: adverseData ? adverseData.data : [],
  //         backgroundColor: "#1ca3f0",
  //         borderColor: "#a8e0ff",
  //       },
  //       {
  //         label: "No Adverse Reaction",
  //         data: noAdverseData ? noAdverseData.data : [],
  //         // backgroundColor: "#f08021",
  //         backgroundColor: "#3e517a",
  //         borderColor: "#a8e0ff",
  //       },
  //     ],
  //   };

  //   return data;
  // };

  const mergeAdverseReactionData = (data1, data2) => {
    const mergedData = {};

    data1.forEach((point) => {
      if (!mergedData[point.x]) {
        mergedData[point.x] = point.y;
      } else {
        mergedData[point.x] += point.y;
      }
    });

    data2.forEach((point) => {
      if (!mergedData[point.x]) {
        mergedData[point.x] = point.y;
      } else {
        mergedData[point.x] += point.y;
      }
    });

    return Object.keys(mergedData).map((x) => ({
      x: Number(x),
      y: mergedData[x],
    }));
  };

  const getData = () => {
    const adverseData = data.find((item) => item._id === true)?.data || [];
    const noAdverseData = data.find((item) => item._id === false)?.data || [];

    if (customerType === "harm-reduction") {
      return {
        datasets: [
          {
            label: "Adverse Reaction",
            data: adverseData,
            backgroundColor: "#1ca3f0",
            borderColor: "#a8e0ff",
          },
          {
            label: "No Adverse Reaction",
            data: noAdverseData,
            backgroundColor: "#3e517a",
            borderColor: "#a8e0ff",
          },
        ],
      };
    } else if (customerType === "law-enforcement") {
      const mergedData = mergeAdverseReactionData(adverseData, noAdverseData);

      return {
        datasets: [
          {
            label: "Samples",
            data: mergedData,
            backgroundColor: "#1ca3f0",
            borderColor: "#a8e0ff",
          },
        ],
      };
    }
  };

  return (
    <>
      {!data ? (
        <div className='d-flex justify-content-center align-items-center h-100 bg-light'>
          No data
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <BarChartAdverse
            data={getData()}
            unit={getUnit()}
            period={period}
            displayLegend={customerType === "harm-reduction" ? true : false}
          />
        </div>
      )}
    </>
  );
};

export default AdverseReactionPlot;

import { id } from "date-fns/locale";

const SampleCons = {
  PRESUMED_SUBSTANCES: [
    "Carfentanil",
    "Cocaine",
    "Fentanyl",
    // "Heroin",
    "Isotonitazene",
    // removed Ketamine
    "MDMA",
    "Methamphetamine",
    "Other",
  ],
  TRACE_SUPPORTED: [
    "Carfentanil",
    "Cocaine",
    "Fentanyl",
    "Isotonitazene",
    "Methamphetamine",
    "Other",
  ],
  NEW_PRESUMED_SUBSTANCES: [
    {
      label: "Opiods",
      drugs: [
        "Carfentanil",
        "Fentalog (Fentanyl, Fluorfentanyl, etc.)",
        "Heroin",
        "Nitazene",
      ],
    },
    {
      label: "Stimulants",
      drugs: ["Cocaine", "Methamphetamine", "MDMA/MDA"],
    },
    {
      label: "Depressants",
      drugs: ["Benzodiazepines (Bromazalom, Flubromazolam, Clonazepam, etc.)"],
    },
    {
      label: "Dissociative Drugs",
      drugs: ["Ketamine", "Phencyclidine (PCP)", "Dextromethorphan (DXM)"],
    },
    {
      label: "Prescription Medications", //update any changes to this list in Body.js too
      drugs: [
        "Adderall®",
        "Ativan®",
        "Dilaudid®",
        "Klonopin®",
        "OxyContin®",
        "Ritalin®",
        "Valium®",
        "Vyvanse®",
        "Xanax®",
      ],
    },
    {
      label: "Other Compounds",
      drugs: ["Xylazine", "Other"],
    },
  ],
  COLOURS: [
    {
      label: "Red",
      colours: [
        {
          id: "RED01",
          hexCode: "#EE2F3C",
        },
        {
          id: "RED02",
          hexCode: "#F68F79",
        },
      ],
    },
    {
      label: "Orange",
      colours: [
        {
          id: "ORA01",
          hexCode: "#FBAE46",
        },
        {
          id: "ORA02",
          hexCode: "#FFDEAF",
        },
      ],
    },
    {
      label: "Yellow",
      colours: [
        {
          id: "YEL01",
          hexCode: "#F2EC59",
        },
        {
          id: "YEL02",
          hexCode: "#FAF6C3",
        },
      ],
    },
    {
      label: "Green",
      colours: [
        {
          id: "GRE01",
          hexCode: "#7EC46A",
        },
        {
          id: "GRE02",
          hexCode: "#CDE3C1",
        },
      ],
    },
    {
      label: "Teal",
      colours: [
        {
          id: "TEA01",
          hexCode: "#2BD7C0",
        },
        {
          id: "TEA02",
          hexCode: "#8EE9DD",
        },
      ],
    },
    {
      label: "Blue",
      colours: [
        {
          id: "BLU01",
          hexCode: "#0870D0",
        },
        {
          id: "BLU02",
          hexCode: "#6EBDF3",
        },
      ],
    },
    {
      label: "Violet",
      colours: [
        {
          id: "VIO01",
          hexCode: "#8941BA",
        },
        {
          id: "VIO02",
          hexCode: "#9A92C3",
        },
      ],
    },
    {
      label: "Pink",
      colours: [
        {
          id: "PIN01",
          hexCode: "#ED2485",
        },
        {
          id: "PIN02",
          hexCode: "#F493B0",
        },
      ],
    },
    {
      label: "Brown",
      colours: [
        {
          id: "BRO01",
          hexCode: "#5B3A1E",
        },
        {
          id: "BRO02",
          hexCode: "#D4A87B",
        },
      ],
    },
    {
      colours: [
        {
          label: "Black",
          id: "BLA01",
          hexCode: "#000000",
        },
        {
          label: "Grey",
          id: "GRY01",
          hexCode: "#515251",
        },
        {
          label: "Light Grey",
          id: "GRY02",
          hexCode: "#AFACAC",
        },
        {
          label: "White",
          id: "WHI01",
          hexCode: "#FFFFFF",
        },
        {
          label: "Clear",
          id: "CLE01",
          hexCode: "#AARRGGBB",
        },
      ],
    },
  ],
  TEXTURES: [
    {
      id: "POW",
      label: "Powder",
      img: "TextureIcons/powder.svg",
    },
    {
      id: "PEB",
      label: "Pebble",
      img: "TextureIcons/pebbels2.svg",
    },
    {
      id: "CRY",
      label: "Crystal",
      img: "TextureIcons/crystal.svg",
    },
    {
      id: "TAB",
      label: "Tablet",
      img: "TextureIcons/tablet.svg",
    },
    {
      id: "FLA",
      label: "Flake",
      img: "TextureIcons/flakes.svg",
    },
    {
      id: "CAP",
      label: "Capsule",
      img: "TextureIcons/capsule.svg",
    },
    {
      id: "LIQ",
      label: "Liquid",
      img: "TextureIcons/liquid.svg",
    },
    {
      id: "PAS",
      label: "Paste/Wet Powder",
      img: "TextureIcons/wet_paste.svg",
    },
    {
      id: "OTH",
      label: "Other",
      img: "TextureIcons/other.svg",
    },
  ],
  AR_SYMPTOMS: [
    { id: "ARSymptom1", label: "Nausea", value: "Nausea" },
    { id: "ARSymptom2", label: "Vomiting", value: "Vomiting" },
    { id: "ARSymptom3", label: "Headache", value: "Headache" },
    { id: "ARSymptom4", label: "Itchiness", value: "Itchiness" },
    { id: "ARSymptom5", label: "Skin Lesions", value: "Skin Lesions" },
    { id: "ARSymptom6", label: "Hypothermia", value: "Hypothermia" },
    { id: "ARSymptom7", label: "Hyperthermia", value: "Hyperthermia" },
    { id: "ARSymptom8", label: "Coma", value: "Coma" },
    { id: "ARSymptom9", label: "Memory Loss", value: "Memory Loss" },
    { id: "ARSymptom10", label: "Racing Heart", value: "Racing Heart" },
    {
      id: "ARSymptom11",
      label: "Heart Palpitations",
      value: "Heart Palpitations",
    },
    { id: "ARSymptom12", label: "Paranoia", value: "Paranoia" },
    { id: "ARSymptom13", label: "Psychosis", value: "Psychosis" },
  ],
  AR_INTERVENTION: [
    { id: "AR1", label: "Physical stimulus", value: "Physical stimulus" },
    {
      id: "AR2",
      label: "Light supplemental oxygen",
      value: "Light supplemental oxygen",
    },
    {
      id: "AR3",
      label: "Heavy supplemental oxygen",
      value: "Heavy supplemental oxygen",
    },
    { id: "AR4", label: "Ventilation", value: "Ventilation" },
    { id: "AR5", label: "Naloxone", value: "Naloxone" },
    { id: "AR6", label: "CPR", value: "CPR" },
  ],
  BEHAVIOUR_CHANGE: [
    { id: "PTR1", label: "Increased dose", value: "Increased dose" },
    { id: "PTR2", label: "Used as normal", value: "Used as normal" },
    { id: "PTR3", label: "Lowered dose", value: "Lowered dose" },
    {
      id: "PTR4",
      label: "Used with supervision or with peer",
      value: "Used with supervision or with peer",
    },
    {
      id: "PTR5",
      label: "Sought harm reduction supplies (e.g. Naloxone)",
      value: "Sought harm reduction supplies (e.g. Naloxone)",
    },
    {
      id: "PTR6",
      label: "Did not use/discarded substance",
      value: "Did not use/discarded substance",
    },
  ],

  CONSUMPTION_METHODS: [
    { id: "CM1", label: "Oral", value: "Oral" },
    { id: "CM2", label: "Inhaled", value: "Inhaled" },
    { id: "CM3", label: "Injected", value: "Injected" },
    { id: "CM4", label: "Smoked", value: "Smoked" },
    { id: "CM5", label: "Snorted", value: "Snorted" },
  ],

  ANALYTE_NAMES: [
    {
      modelName: "FUB-AMB",
      displayName: "AMB-FUBINACA (FUB-AMB)",
    },
    {
      modelName: "MDMA",
      displayName: "3-4 Methylenedioxymethamphetamine (MDMA)",
    },
    {
      modelName: "MSM",
      displayName: "Methylsulfonylmethane (MSM)",
    },
    {
      modelName: "MorphineTab",
      displayName: "Morphine Tablet",
    },
    {
      modelName: "PCP",
      displayName: "Phencyclidine (PCP)",
    },
    {
      modelName: "PEG",
      displayName: "Polyethylene Glycol (PEG)",
    },

    {
      modelName: "GHB",
      displayName: "Gamma-Hydroxybutyrate (GHB)",
    },
    {
      modelName: "BagBlue",
      displayName: "Plastic Bag",
    },
    {
      modelName: "BagPink",
      displayName: "Plastic Bag",
    },
    {
      modelName: "BagTransparent",
      displayName: "Plastic Bag",
    },
    {
      modelName: "BagYellow",
      displayName: "Plastic Bag",
    },
    {
      modelName: "BakingSoda",
      displayName: "Baking Soda",
    },

    {
      modelName: "LGlutamine",
      displayName: "L-Glutamine",
    },
    {
      modelName: "4ANPP",
      displayName: "4-ANPP",
    },
    {
      modelName: "BG",
      displayName: "Chip Background",
    },
    {
      modelName: "ABFubinica",
      displayName: "AB-Fubinaca",
    },
  ],
};

export default SampleCons;

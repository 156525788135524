import { ColourCircleC } from "../ui/ColourCircle";
import { CardC } from "../ui/Cards";

import { useParamsContext } from "../../contexts";

export default function SampleInformation({ sample }) {
  const { getColour, getTexture } = useParamsContext(); // getColour and getTexture are functions that are passed down as props from the context

  let colour = getColour(sample.colourId);
  let texture = sample.texture ? getTexture(sample.texture.id) : {};

  return (
    <div className=' my-2 full-height'>
      <div className='card full-height'>
        <div className='card-body'>
          <h5 className='card-title text-primary'>Sample Information</h5>
          <div className=' d-flex justify-content-between flex-wrap'>
            <div className='px-1'>
              <h6>Sample ID:</h6>
              <p className='mb-0'>{sample.sampleId}</p>
            </div>
            <div className='px-1'>
              <h6>Presumed Substance:</h6>
              <p className='mb-0'>
                {sample.presumedSubstance === "Other" &&
                sample.presumedSubDescription
                  ? `${sample.presumedSubstance} - ${sample.presumedSubDescription}`
                  : sample.presumedSubstance}
              </p>
            </div>
            <div className='px-1'>
              <h6>Colour:</h6>
              <div className='mb-0'>
                {colour && <ColourCircleC data={colour} />}
              </div>
            </div>
            <div className='px-1'>
              <h6>Sample Texture:</h6>
              <div className='mb-0'>{<CardC data={texture} />}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

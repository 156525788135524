import {
  Modal,
  Button,
  Form,
  ModalBody,
  ModalFooter,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useEffect, useState } from "react";

import TestStripForm from "./TestStripForm";
import OtherTechniquesForm from "./OtherTechniquesForm";

import { v4 as uuidv4 } from "uuid";

const OtherResultModal = ({
  show,
  handleClose,
  handleSave,
  resultData,
  isNewResult,
}) => {
  const [isResultData, setIsResultData] = useState(false);
  const [formData, setFormData] = useState({
    testType: "",
  });
  const [validated, setValidated] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);

  useEffect(() => {
    if (resultData) {
      setFormData(resultData);
      setIsResultData(true);
    }
  }, [resultData]);

  const handleChange = (id, value) => {
    setFormData({ ...formData, [id]: value });

    if (id === "testType") {
      setFormData({
        ...formData,
        [id]: value,
        results: "",
        type: "",
        otherTechniqueType: "",
        comments: "",
      });
    }
  };

  const resetStates = () => {
    setFormData({}); // Clear form data;
    setValidated(false);
    setShowValidationError(false);
    setIsResultData(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      !formData.testType ||
      !formData.results
    ) {
      event.stopPropagation();
      setShowValidationError(true);
      setValidated(true);
    } else {
      // If it's a new result, generate a new UUID
      if (!formData.resultId) {
        formData.resultId = uuidv4(); // Generate a new UUID
      }

      // Add date to the form data. If it's a new result, add addedDt, else add editedDt
      if (isNewResult) {
        formData.addedDt = new Date().toISOString();
      } else {
        formData.editedDt = new Date().toISOString();
      }

      handleSave(formData);
      resetStates();
      console.log("Form submitted:", formData);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        resetStates();
      }}
      backdrop='static'
      size='lg'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isResultData ? "Edit Test Results" : "Enter Test Results"}
        </Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label className='fs-6 fw-semibold'>Test Type:</Form.Label>
            <div className='mb-3'>
              <Form.Check
                inline
                name='testType'
                type='radio'
                id='testStrip'
                label='Test Strip'
                checked={formData.testType === "testStrip"}
                onChange={(e) => handleChange("testType", e.target.id)}
                required
                disabled={isResultData}
              />
              <Form.Check
                inline
                name='testType'
                type='radio'
                id='otherTechnique'
                label='Other Technique'
                checked={formData.testType === "otherTechnique"}
                onChange={(e) => handleChange("testType", e.target.id)}
                required
                disabled={isResultData}
              />

              {showValidationError && !formData.testType && (
                <div className='invalid-feedback d-block'>
                  Please select a test type.
                </div>
              )}
            </div>
          </Form.Group>
          {formData.testType === "testStrip" && (
            <TestStripForm
              formData={formData}
              handleChange={handleChange}
              showValidationError={showValidationError}
            />
          )}
          {formData.testType === "otherTechnique" && (
            <OtherTechniquesForm
              formData={formData}
              handleChange={handleChange}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              handleClose();
              resetStates();
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default OtherResultModal;

import { useState } from "react";

// react-bootstrap
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

//api calls
import { useSampleEdit } from "./../../api/posts";

export default function Notes({ sampleNotes, id }) {
  const [notes, setNotes] = useState(sampleNotes);
  const [isSaved, setIsSaved] = useState(sampleNotes || notes ? true : false);

  const {
    mutateAsync,
    isSuccess: editSuccess,
    isError: editError,
    isLoading: editLoading,
  } = useSampleEdit(id);

  async function handleNotesSave() {
    if (notes !== sampleNotes) {
      await mutateAsync({ id: id, data: { description: notes } });
    }
    setIsSaved(notes ? true : false);
  }
  return (
    <div className='my-2 full-height'>
      <div className='card full-height'>
        <div className='card-body'>
          <Form>
            <div className='d-flex justify-content-between mb-2'>
              <Form.Label className='h5 card-title text-primary'>
                Notes
              </Form.Label>
              {/* if the notes are saved, show the edit button, else show the save button */}
              {isSaved ? (
                <Button
                  variant='secondary'
                  type='submit'
                  onClick={(e) => {
                    e.preventDefault();
                    setIsSaved(false);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  variant='outline-primary'
                  type='submit'
                  disabled={editLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNotesSave();
                  }}
                >
                  {editLoading ? "Saving..." : "Save"}
                </Button>
              )}
            </div>

            <Form.Control
              as={"textarea"}
              rows={6}
              placeholder='Enter notes here'
              defaultValue={sampleNotes}
              disabled={isSaved}
              style={{ opacity: `${isSaved ? "0.6" : ""}` }}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Form>
        </div>
      </div>
    </div>
  );
}

import { render } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { Stepper } from "react-form-stepper";
import { Tooltip } from "bootstrap";
import styleDate from "../../util/dateStyler";

export function CardA({
  title = "",
  subtitle = "",
  subtitle2 = "",
  help = "",
  children,
  steps,
  step = "",
  background = "white",
  textColour = "black",
  borderRadius = "",
}) {
  useEffect(() => {
    const initializeTooltips = () => {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      const tooltipList = [...tooltipTriggerList].map(
        (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
      );
    };

    // Use a slight delay to ensure the DOM is fully rendered
    const timeoutId = setTimeout(initializeTooltips, 100);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount
  }, []);
  return (
    <div
      className='shadow card h-100 d-flex flex-grow-1 '
      style={{ borderRadius: "15px" }}
    >
      <div className='card-body h-100 d-flex flex-column flex-grow-1 justify-content-between'>
        <div>
          <h5 className='card-title'>
            {title}
            {help !== "" && (
              <span className='ms-2' style={{ fontSize: "1rem" }}>
                <i
                  className='far fa-question-circle '
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-title={help}
                  style={{ cursor: "pointer" }}
                ></i>
              </span>
            )}
          </h5>
          {subtitle2 ? (
            <>
              <h6 className='card-subtitle mb-1 text-muted'>{subtitle}</h6>
              <h6 className='card-subtitle mb-4 text-muted fst-italic'>
                {subtitle2}
              </h6>
            </>
          ) : (
            <h6 className='card-subtitle mb-1 text-muted '>{subtitle}</h6>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

// CardA.defaultProps = {
//   icon: "",
//   title: "",
//   subtitle: "",
//   help: "",
//   step: "",
// };

export function CardB({
  title = "",
  subtitle = "",
  img = "",
  description = "",
  selected,
  updateTexture,
  id,
}) {
  let imgSrc = require("../../images/".concat(img));
  return (
    <button
      className={selected ? "card border-3 border-dark" : "card"}
      style={
        selected && title === "Other"
          ? {
              width: "345px",
              height: "60px",
              margin: "2px",
              transition: "width 1s",
            }
          : {
              width: "170px",
              height: "60px",
              margin: "2px",
              transition: "width 1s",
            }
      }
      onClick={() => {
        updateTexture(title, id, img);
      }}
    >
      <div className='card-body d-flex p-1 justify-content-space-between w-100'>
        <img
          src={imgSrc}
          width={50}
          height={50}
          // className='card-img-bottom'
          alt={title}
        />
        <label
          className={
            selected && title === "Other"
              ? "card-text align-self-center m-1"
              : "card-text align-self-center m-1 w-100"
          }
          style={{ fontSize: "14px" }}
        >
          {title}
        </label>
        {selected && title === "Other" ? (
          // <div className='w-100 d-inline-flex flex-column'>
          <input
            type='text'
            className='form-control m-1 w-100'
            placeholder='Please specify (required)'
            defaultValue={description}
            aria-label='Other'
            aria-describedby='basic-addon2'
            style={{ fontSize: "12px" }}
            maxLength={"30"}
            onChange={(e) => updateTexture(title, id, img, e.target.value)}
          />
        ) : // {/* <small>20 characters max.</small> */}
        // </div>
        null}
      </div>
    </button>
  );
}
export function CardC({ data }) {
  if (data === undefined || Object.keys(data).length === 0) {
    return <p className='mb-0'>n/a</p>;
  } else {
    const { label, img, description } = data;
    let imgSrc = require("../../images/".concat(img));
    return (
      <div className='d-flex'>
        <img
          src={imgSrc}
          width={30}
          height={30}
          // className='card-img-bottom'
          alt={label}
        />
        <p className='card-text align-self-center ms-2 mb-0'>
          {data.label === "Other" && data.description
            ? `${label} - ${description}`
            : label}
        </p>
        {/* <h6 className='card-subtitle mb-4 text-muted'>{subtitle}</h6> */}
      </div>
    );
  }
}

export function CardD({
  icon = "",
  title = "",
  subtitle = "",
  subtitle2 = "",
  help = "",
  spacing = "",
  buttonTitle = "",
  buttonFunction = () => {},
  buttonStyle = "",
  children,
}) {
  useEffect(() => {
    const initializeTooltips = () => {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      const tooltipList = [...tooltipTriggerList].map(
        (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
      );
    };

    // Use a slight delay to ensure the DOM is fully rendered
    const timeoutId = setTimeout(initializeTooltips, 100);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount
  }, []);
  return (
    <div className='shadow card h-100 d-flex flex-grow-1'>
      <div className='card-body h-100 d-flex flex-column flex-grow-1'>
        {icon !== "" && <i className={"float-end mt-1 " + icon}></i>}
        <div className='d-flex flex-row'>
          <div className={spacing}>
            <h5 className='card-title w-75'>
              {title}
              {help !== "" && (
                <span className='ms-2' style={{ fontSize: "1rem" }}>
                  <i
                    className='far fa-question-circle text-primary'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title={help}
                    style={{ cursor: "pointer" }}
                  ></i>
                </span>
              )}
            </h5>

            {subtitle2 ? (
              <>
                <h6 className='card-subtitle mb-1 text-muted'>{subtitle}</h6>
                <h6 className='card-subtitle mb-4 text-muted fst-italic'>
                  {subtitle2}
                </h6>
              </>
            ) : (
              <h6 className='card-subtitle mb-4 text-muted'>{subtitle}</h6>
            )}
          </div>
          <div className={spacing}>
            <button
              type='button'
              className={"btn mx-auto w-25 float-end " + buttonStyle}
              onClick={() => {
                buttonFunction();
              }}
            >
              {buttonTitle}
            </button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}

export function ScoreCard({
  title = "",
  help = "",
  children,
  background = "white",
  textColour = "black",
  isFetchingScores = false,
  value = "",
  unit = "",
  vsValue = "",
}) {
  useEffect(() => {
    const initializeTooltips = () => {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      const tooltipList = [...tooltipTriggerList].map(
        (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
      );
    };

    // Use a slight delay to ensure the DOM is fully rendered
    const timeoutId = setTimeout(initializeTooltips, 100);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount
  }, []);
  return (
    <div
      className='shadow card h-100 py-xl-3'
      style={{
        background: background,
        borderRadius: "15px",
      }}
    >
      <div
        className=' d-flex flex-wrap card-body h-100'
        style={{ color: textColour }}
      >
        <div className='flex-grow-1'>
          <h5 className='card-title w-100'>
            {title}
            {help !== "" && (
              <span
                className='ms-2'
                style={{ fontSize: "1rem", color: textColour }}
              >
                <i
                  className='far fa-question-circle '
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-title={help}
                  style={{ cursor: "pointer", color: textColour }}
                ></i>
              </span>
            )}
          </h5>

          {!isFetchingScores && (
            <>
              <p className='mb-0 ' style={{ color: textColour }}>
                <span className='text-nowrap'>Previous Period:</span>
                <span className={" me-2"}>{" " + vsValue + unit}</span>
              </p>
              {children}
            </>
          )}
        </div>
        <div className='flex-shrink-0 mt-3 mt-md-0'>
          <div className='w-100'>
            {isFetchingScores ? (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              <>
                <p
                  className='text-end mb-0 fs-3 fw-bold text-nowrap'
                  style={{ color: textColour }}
                >
                  {value + unit}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function OtherResultCard({
  result,
  setSelectedResult,
  setModalShow,
  setShowDeleteModal,
  setSelectedDeleteResult,
  setIsNewResult,
}) {
  return (
    <div
      className='result-card p-0'
      style={{ minWidth: "30%", maxWidth: "30%" }}
    >
      <h6 className='card-header'>
        {result.testType === "testStrip"
          ? `Test Strip -  ${result.type}`
          : result.type === "Other"
          ? `${result.type} - ${result.otherTechniqueType}`
          : result.type}
      </h6>
      <div className='card-body'>
        <div className=''>
          <strong>Results: </strong>
          {result.results}
        </div>{" "}
        <div>
          {" "}
          <strong>Date: </strong>
          {styleDate(result.addedDt)}
        </div>
        <div className='text-wrap text-break'>
          <strong>Comments: </strong>
          {result.comments}
        </div>
        <div className='hover-buttons'>
          <div className='d-flex justify-content-center pt-2'>
            {/* <button
                                              className='btn btn-primary btn-sm me-2'
                                              style={{ minWidth: "60px" }}
                                            >
                                              View
                                            </button> */}
            <button
              className='btn btn-secondary btn-sm me-2'
              style={{ minWidth: "60px" }}
              onClick={() => {
                setSelectedResult(result);
                setModalShow(true);
                setIsNewResult(false);
              }}
            >
              Edit
            </button>
            <button
              className='btn btn-danger btn-sm'
              style={{ minWidth: "60px" }}
              onClick={() => {
                setShowDeleteModal(true);
                setSelectedDeleteResult(result);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
//components
import AdverseReaction from "./PostConsumption/AdverseReaction";
//constants
import SampleCons from "../../constants/sample";

//ui
import CreatableSelect from "react-select/creatable";
import { Button, Form } from "react-bootstrap";

//api calls
import { useSampleEdit } from "./../../api/posts";

export default function PostConsumption({ id, postConsumption, categories }) {
  const [isSaved, setIsSaved] = useState(postConsumption ? true : false);
  const [formData, setFormData] = useState(
    postConsumption || {
      tested: "",
      consumptionLocation: "",
      behaviourChange: "",
      consumptionMethod: "",
      adverse: false,
      symptoms: [],
      intervention: [],
    }
  );
  const [isEffectDone, setIsEffectDone] = useState(false);

  useEffect(() => {
    if (checkCategories()) {
      const intervention = categories.map((category) => {
        return SampleCons.AR_INTERVENTION.find(
          (int) => int.id === category.categoryId
        );
      });

      setFormData((prev) => {
        return { ...prev, adverse: true, intervention: intervention };
      });
      setIsSaved(true);
    }
    setIsEffectDone(true);
  }, []);

  const {
    mutateAsync,
    isSuccess: editSuccess,
    isError: editError,
    isLoading: editLoading,
  } = useSampleEdit(id);

  const checkCategories = () => {
    if (categories && categories.length > 0 && !postConsumption) {
      return true;
    }
    return false;
  };

  async function handleSave() {
    if (!postConsumption || formData !== postConsumption) {
      await mutateAsync({
        id: id,
        data: { postConsumption: formData },
      });
    }
    setIsSaved(true);
  }

  const handleChange = (id, value) => {
    setFormData((prev) => {
      return { ...prev, [id]: value };
    });
  };

  const customSelectStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused || state.isSelected ? "#1ca3f0" : "#ced4da",
      boxShadow: "none",
      "&:hover": { borderColor: "#1ca3f0", boxShadow: "#1ca3f0" },
    }),
    multiValue: (baseStyles, state) => ({
      ...baseStyles,
      opacity: state.isDisabled ? 0.6 : 1, // Reduce opacity for multi-value when disabled
    }),
  };

  return (
    <div className='my-2 full-height'>
      <div className='card full-height'>
        <div className='card-body'>
          <Form>
            <div className='d-flex justify-content-between'>
              <h5 className='card-title text-primary'>
                Post-Consumption Information
              </h5>
              {isSaved ? (
                <Button
                  variant='secondary'
                  type='submit'
                  onClick={(e) => {
                    e.preventDefault();
                    setIsSaved(false);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  variant='outline-primary'
                  type='submit'
                  disabled={editLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSave();
                  }}
                >
                  {editLoading ? "Saving..." : "Save"}
                </Button>
              )}
            </div>
            <div className='d-flex flex-column flex-md-row'>
              <div className='col-12 col-md-6 px-md-3 d-flex flex-column gap-4'>
                <div className='d-flex flex-wrap '>
                  <Form.Group className='d-flex flex-nowrap gap-2 w-auto me-3 me-xl-5'>
                    <h6>Scan Timing:</h6>

                    <Form.Check
                      inline
                      type='radio'
                      label='Pre-use'
                      id='pre-use'
                      name='tested'
                      className='text-nowrap'
                      defaultChecked={formData.tested == "pre-use"}
                      disabled={isSaved}
                      onChange={(e) => handleChange("tested", e.target.id)}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      label='Post-use'
                      id='post-use'
                      name='tested'
                      className='text-nowrap'
                      defaultChecked={formData.tested == "post-use"}
                      disabled={isSaved}
                      onChange={(e) => handleChange("tested", e.target.id)}
                    />
                  </Form.Group>

                  <Form.Group className='d-flex flex-nowrap gap-2 w-auto '>
                    <h6>Consumed:</h6>

                    <Form.Check
                      inline
                      type='radio'
                      label='On-site'
                      id='on-site'
                      name='consumptionLocation'
                      className='text-nowrap'
                      defaultChecked={formData.consumptionLocation == "on-site"}
                      disabled={isSaved}
                      onChange={(e) =>
                        handleChange("consumptionLocation", e.target.id)
                      }
                    />
                    <Form.Check
                      inline
                      type='radio'
                      label='Off-site'
                      id='off-site'
                      name='consumptionLocation'
                      className='text-nowrap'
                      defaultChecked={
                        formData.consumptionLocation == "off-site"
                      }
                      disabled={isSaved}
                      onChange={(e) =>
                        handleChange("consumptionLocation", e.target.id)
                      }
                    />
                  </Form.Group>
                </div>
                <div>
                  <h6>Behaviour Change</h6>
                  <CreatableSelect
                    isMulti
                    options={SampleCons.BEHAVIOUR_CHANGE}
                    placeholder='Select from list or type a custom option'
                    isDisabled={isSaved}
                    styles={customSelectStyle}
                    defaultValue={formData.behaviourChange}
                    onChange={(e) => handleChange("behaviourChange", e)}
                  />
                </div>
                <div>
                  <h6>Consumption Method</h6>
                  <CreatableSelect
                    options={SampleCons.CONSUMPTION_METHODS}
                    placeholder='Select...'
                    isDisabled={isSaved}
                    styles={customSelectStyle}
                    defaultValue={formData.consumptionMethod}
                    onChange={(e) => handleChange("consumptionMethod", e)}
                  />
                </div>
              </div>
              <div className='d-none d-md-block border-end'></div>
              {isEffectDone && (
                <AdverseReaction
                  isSaved={isSaved}
                  customSelectStyle={customSelectStyle}
                  handleChange={handleChange}
                  formData={formData}
                />
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

import { useCallback, useState, useEffect } from "react";

//constants
import SampleCons from "../../../constants/sample";

// antd
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import { Line } from "react-chartjs-2";

const palette = [
  "#56B4E9",
  "#E69F00",
  "#009E73",
  "#F0E442",
  "#0072B2",
  "#D55E00",
  "#CC79A7",
];

const graphOptions = {
  scales: {
    x: {
      title: {
        display: true,
        text: "Raman shift (cm^-1)",
      },
    },
    y: {
      title: {
        display: true,
        text: "Intenisty (a.u.)",
      },
    },
  },
  elements: {
    point: {
      radius: 2,
    },
  },
  plugins: {
    legend: {
      align: "end",
      display: true,
      labels: {
        boxWidth: 10,
      },
    },
  },
};

//change analyte name to display name for plot labels
const getAnalyteName = (analyte) => {
  return (
    SampleCons.ANALYTE_NAMES.find((item) => item.modelName === analyte)
      ?.displayName || addSpaces(analyte)
  );
};

//add spaces between camel case for analyte names in plot labels
const addSpaces = (string) => {
  return string.replace(/(?!\[)([^A-Z])([A-Z])(?![A-Z])/g, "$1 $2").trim();
};

export function PlotAccordian({
  refData,
  isRefSuccess,
  header,
  processedData,
  wavenumbers,
}) {
  //states
  const [spectraChartDataLoading, setSpectraChartDataLoading] = useState(true);
  const [spectraChartData, setSpectraChartData] = useState({});

  const { Panel } = Collapse;

  // returns true if processedData is not empty
  const needsGraph = useCallback(() => {
    return processedData.length > 0;
  }, [processedData]);

  //update the graph data when processedData, wavenumbers, or refData changes
  useEffect(() => {
    if (needsGraph()) {
      const data = getGraphData(processedData, wavenumbers, refData);
      setSpectraChartData(data);
      setSpectraChartDataLoading(false);
    } else {
      setSpectraChartData({});
      setSpectraChartDataLoading(true);
    }
  }, [processedData, wavenumbers, refData, needsGraph]);

  // generate graph data
  // X labels: wavenumber
  // Y data: scan data
  function getGraphData(processedData, wavenumbers, refData) {
    const data = {};
    let refDatasets = [];

    const xValues = wavenumbers[0];
    data.labels = xValues;

    const scanDataset = {
      data: processedData[0],
      label: "Scan",
      fill: false,
      borderColor: "#000000",
      backgroundColor: "#000000",
      pointRadius: 1.5,
    };

    //if reference data is available, add it to the graph
    if (refData.length > 0 && isRefSuccess) {
      refDatasets = refData
        .filter((item) => item.data.data?.length > 0)
        .map((item, index) => {
          return {
            data: item.data.data,
            label: getAnalyteName(item.data.label),
            fill: false,
            borderColor: palette[index],
            backgroundColor: palette[index],
            hidden: true,
            pointRadius: 1.5,
          };
        });
    }

    data.datasets = [scanDataset];
    refDatasets.forEach((item) => data.datasets.push(item));

    return data;
  }

  return (
    <Collapse
      expandIconPosition='start'
      ghost={true}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          rotate={isActive ? 90 : 0}
          style={{ color: "#1CA3F0", fontSize: "16px" }}
        />
      )}
    >
      <Panel
        header={header}
        style={{
          fontSize: "small",
          fontWeight: "500",
        }}
      >
        {spectraChartDataLoading ? (
          <span>Loading...</span>
        ) : (
          <div className='d-flex flex-column'>
            <p className='fst-italic'>
              Click on the legend item to display/disable the spectrum.
            </p>
            <div className='w-100 align-self-center'>
              <Line data={spectraChartData} options={graphOptions} />
            </div>
          </div>
        )}
      </Panel>
    </Collapse>
  );
}

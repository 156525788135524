import { useState } from "react";
import SampleCons from "../../../constants/sample";
import Form from "react-bootstrap/Form";

import CreatableSelect from "react-select/creatable";

export default function AdverseReaction({
  postConsumption,
  isSaved,
  customSelectStyle,
  handleChange,
  formData,
}) {
  const [isAdverseReaction, setIsAdverseReaction] = useState(formData.adverse);
  return (
    <div className='col-12 col-md-6 py-3 py-md-0 px-md-3 d-flex flex-column gap-4'>
      <div className='d-flex justify-content-start'>
        <Form.Check reverse type='switch' id='adverseToggle'>
          <Form.Check.Input
            defaultChecked={formData.adverse}
            onChange={(e) => {
              setIsAdverseReaction(e.target.checked);
              handleChange("adverse", e.target.checked);
            }}
            disabled={isSaved}
          ></Form.Check.Input>
          <Form.Check.Label
            className='h6 mb-0'
            style={{ opacity: "1" }}
            htmlFor='adverseToggle'
          >
            Adverse Reaction
          </Form.Check.Label>
        </Form.Check>

        <span className='ps-2' style={{ opacity: isSaved ? "0.5" : "1" }}>
          {isAdverseReaction ? "Yes" : "No"}
        </span>
      </div>

      {/* if adverse reaction is true, display the following */}
      {isAdverseReaction && (
        <>
          <div className='flex-column'>
            <h6>Reaction Symptoms</h6>
            <CreatableSelect
              isMulti
              options={SampleCons.AR_SYMPTOMS}
              placeholder='Select from list or type a custom option'
              isDisabled={isSaved}
              styles={customSelectStyle}
              defaultValue={formData.symptoms}
              onChange={(e) => handleChange("symptoms", e)}
            />
          </div>
          <div className='flex-column'>
            <h6>Required Intervention</h6>
            <CreatableSelect
              isMulti
              options={SampleCons.AR_INTERVENTION}
              placeholder='Select from list or type a custom option'
              isDisabled={isSaved}
              styles={customSelectStyle}
              defaultValue={formData.intervention}
              onChange={(e) => handleChange("intervention", e)}
            />
          </div>
        </>
      )}
    </div>
  );
}

import { useEffect } from "react";
import { Reachback } from "./SampleResults/Reachback";
import { SequenceB } from "../ui/Sequence";
import { PlotAccordian } from "./SampleResults/PlotAccordian";

//api calls
import { useReferenceSpectrum } from "../../api";

export default function SampleResults({ sample }) {
  // Filter tests for bulk and trace
  let bulkTests = sample.tests.filter((test) => test.scanType === "bulk-burst");
  let traceTests = sample.tests.filter(
    (test) => test.scanType === "trace-burst"
  );

  // Get all findings from bulk and trace tests. Use reachback findings if available
  let bulkFindings = bulkTests
    .map((test) => {
      if (test.rb_findings) {
        return test.rb_findings;
      } else {
        return test.findings;
      }
    })
    .flat();

  let traceFindings = traceTests
    .map((test) => {
      if (test.rb_findings) {
        return test.rb_findings;
      } else {
        return test.findings;
      }
    })
    .flat();

  //fetch the bulk reference spectra
  const {
    results: bulkRefData,
    isSuccess: isBulkRefSuccess = false,
    isError: isBulkRefError = false,
    isLoading: isBulkRefLoading,
  } = useReferenceSpectrum(
    bulkFindings.map((finding) => {
      return {
        collection: "bulk-burst",
        analyte: finding.substance,
      };
    })
  );

  //fetch the trace reference spectra
  const {
    results: traceRefData,
    isSuccess: isTraceRefSuccess = false,
    isError: isTraceRefError = false,
    isLoading: isTraceRefLoading,
  } = useReferenceSpectrum(
    traceFindings.map((finding) => {
      return {
        collection: "trace-burst",
        analyte: finding.substance,
      };
    })
  );

  return (
    <div className='my-2 full-height'>
      <div className='card full-height'>
        <div className='card-body'>
          <h5 className='card-title text-primary'>
            Amplifi ID
            <sup>
              <small>TM</small>
            </sup>{" "}
            Sample Results
          </h5>

          <div className='d-flex flex-wrap justify-content-between '>
            <div className='col-12 col-xl-6 mb-1 order-1'>
              <h6>
                Bulk Scan{" "}
                {sample.isErrorB && sample.reachback && (
                  <i
                    className='bi bi-exclamation-triangle-fill'
                    style={{
                      color: "#AE0000",
                      marginRight: "10px",
                    }}
                  ></i>
                )}
              </h6>
              <div className='row'>
                {bulkTests.length > 0 ? (
                  <div className=' mb-2'>
                    <SequenceB
                      findings={bulkFindings.map((finding) => {
                        return {
                          substance: finding.substance,
                          pureSubstance: finding.Pure_substance,
                        };
                      })}
                    />
                  </div>
                ) : (
                  <p>No bulk tests found</p>
                )}
              </div>
            </div>

            <div className='col-12 col-xl-6 mb-1 order-3 order-xl-2'>
              <h6>
                Trace Scan{" "}
                {sample.isErrorT && sample.reachback && (
                  <i
                    className='bi bi-exclamation-triangle-fill'
                    style={{
                      color: "#AE0000",
                      marginRight: "10px",
                    }}
                  ></i>
                )}
              </h6>
              <div className='row'>
                {traceTests.length > 0 ? (
                  <div className=' mb-2'>
                    <SequenceB
                      findings={traceFindings.map((finding) => {
                        return {
                          substance: finding.substance,
                          pureSubstance: finding.Pure_substance,
                        };
                      })}
                    />
                  </div>
                ) : (
                  <p>No trace tests found</p>
                )}
              </div>
            </div>
            <div className='col-12 col-xl-6 mb-2 order-2 order-xl-3'>
              {bulkTests.length > 0 && (
                <PlotAccordian
                  refData={bulkRefData}
                  isRefSuccess={isBulkRefSuccess}
                  header='View Bulk Spectra'
                  processedData={bulkTests.map(
                    (test) => test.processed_data_plot
                  )}
                  wavenumbers={bulkTests.map((test) => test.wavenumber_plot)}
                />
              )}
            </div>
            <div className='col-12 col-xl-6 mb-2 order-4'>
              {traceTests.length > 0 && (
                <PlotAccordian
                  refData={traceRefData}
                  isRefSuccess={isTraceRefSuccess}
                  header='View Trace Spectra'
                  processedData={traceTests.map(
                    (test) => test.processed_data_plot
                  )}
                  wavenumbers={traceTests.map((test) => test.wavenumber_plot)}
                />
              )}
            </div>
          </div>
          <Reachback sample={sample} />
        </div>
      </div>
    </div>
  );
}

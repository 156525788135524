import React from "react";
import Chart from "chart.js/auto";
import { enUS } from "date-fns/locale";
import "chartjs-adapter-date-fns";

class BarChartAdverse extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data = this.props.data;
    this.myChart.update();
  }

  componentDidMount() {
    let now = new Date().setHours(12);
    this.myChart = new Chart(this.canvasRef.current, {
      type: "bar",
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2.6,
        layout: { padding: 0 },
        scales: {
          xAxis: {
            stacked: true,
            type: "time",
            title: {
              text: "Date",
              display: true,
              font: { family: "Helvetica", size: 15, weight: "bold" },
            },
            time: {
              unit: this.props.unit,
              displayFormats: {
                day: "MMM dd",
                week: "MMM dd",
                month: "MMM dd",
              },
            },
            adapters: {
              date: {
                locale: enUS,
              },
            },
            max: now,
            min: new Date(now - this.props.period * 24 * 60 * 60 * 1000),
          },
          yAxis: {
            stacked: true,
            title: {
              text: "Amount",
              display: true,
              font: { family: "Helvetica", size: 15, weight: "bold" },
            },
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          legend: {
            display: this.props.displayLegend,
            position: "bottom",
          },
          tooltip: {
            callbacks: {
              title: function (context) {
                let label = context[0].label;
                let title = label.slice(0, -15);
                return title;
              },
            },
          },
        },
      },
      data: this.props.data,
    });
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}

export default BarChartAdverse;

import { useEffect, useState } from "react";

import { useSampleEdit } from "../../api/posts";

import OtherResultModal from "./OtherResults/OtherResultModal";
import DeleteResultModal from "./OtherResults/DeleteResultModal";
import { OtherResultCard } from "../ui/Cards";
import { set } from "date-fns";

export default function OtherResults({ id, otherResults }) {
  const [modalShow, setModalShow] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const [selectedDeleteResult, setSelectedDeleteResult] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isNewResult, setIsNewResult] = useState(false);

  const {
    mutateAsync,
    isSuccess: editSuccess,
    isError: editError,
  } = useSampleEdit(id);

  async function handleSaveResult(resultData) {
    console.log("saving result:", resultData);

    let updatedResults = [];
    if (isNewResult) {
      updatedResults = [...otherResults, resultData];
    } else {
      updatedResults = otherResults.map((result) =>
        result.resultId === resultData.resultId ? resultData : result
      );
    }

    const data = {
      id: id,
      data: { otherResults: updatedResults },
    };

    setModalShow(false);
    setSelectedResult(null);
    setIsNewResult(false);

    await mutateAsync(data);
  }

  async function handleDeleteResult() {
    console.log(`deleting result: ${selectedDeleteResult.resultId}`);
    const updatedResults = otherResults.filter(
      (result) => result.resultId !== selectedDeleteResult.resultId
    );

    const data = {
      id: id,
      data: { otherResults: updatedResults },
    };

    setShowDeleteModal(false);
    setSelectedDeleteResult(null);

    await mutateAsync(data);
  }

  return (
    <div className='my-2 full-height'>
      <div className='card full-height'>
        <div className='card-body d-flex flex-column'>
          <div
            className={
              otherResults && otherResults.length > 0
                ? "d-flex justify-content-between flex-wrap mb-2"
                : "full-height justify-content-between flex-grow-1"
            }
          >
            <h5 className='card-title text-primary'>
              Other Analytical Results
            </h5>
            <div
              className={
                otherResults && otherResults.length > 0
                  ? " justify-content-end"
                  : "full-height justify-content-center align-items-center"
              }
              tabIndex='0'
              {...(otherResults && otherResults.length >= 6
                ? {
                    "data-bs-toggle": "tooltip",
                    "data-bs-placement": "left",
                    "data-bs-title":
                      "Limit: 6 results. Please delete a result to add a new one.",
                  }
                : {})}
            >
              <button
                type='button'
                className={`btn btn-outline-primary align-self-center ${
                  otherResults && otherResults.length > 0 ? "" : "w-50"
                }`}
                onClick={(event) => {
                  event.preventDefault();
                  setSelectedResult(null);
                  setModalShow(true);
                  setIsNewResult(true);
                }}
                disabled={otherResults && otherResults.length >= 6}
              >
                Add New Result
              </button>
            </div>
          </div>
          {otherResults && otherResults.length > 0 && (
            <div className='d-flex flex-wrap justify-content-evenly gap-2'>
              {otherResults.map((result, index) => (
                <OtherResultCard
                  key={index}
                  result={result}
                  setSelectedResult={setSelectedResult}
                  setModalShow={setModalShow}
                  setShowDeleteModal={setShowDeleteModal}
                  setSelectedDeleteResult={setSelectedDeleteResult}
                  setIsNewResult={setIsNewResult}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <OtherResultModal
        show={modalShow}
        isNewResult={isNewResult}
        handleClose={() => {
          setModalShow(false);
          setSelectedResult(null);
          setIsNewResult(false);
        }}
        handleSave={(resultData) => handleSaveResult(resultData)}
        resultData={selectedResult}
      />
      <DeleteResultModal
        show={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
          setSelectedDeleteResult(null);
        }}
        handleDelete={() => handleDeleteResult()}
      />
    </div>
  );
}
